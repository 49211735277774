import React, { useEffect, useState } from 'react'

let timeout

export default function TableSearch({ setSearchQuery, label }) {
  const [inputValue, setInputValue] = useState("")

  // Initialize input value from URL and localStorage on component mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const searchParam = params.get('search')

    // Prefer URL param over localStorage
    if (searchParam) {
      setInputValue(searchParam)
      setSearchQuery(searchParam)
    }
  }, [])

  const handleInputChange = e => {
    const query = e.target.value || null
    setInputValue(query)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearchQuery(query)
      // Update URL query parameter
      const params = new URLSearchParams(window.location.search)
      if (query) {
        params.set('search', query)
        localStorage.setItem(`tableSearchQuery-${label}`, query)
      } else {
        params.delete('search')
        localStorage.removeItem(`tableSearchQuery-${label}`)
      }
      const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`
      window.history.pushState({}, '', newUrl)
    }, 500)
  }

  return (
    <input type="text" value={inputValue} onChange={handleInputChange} className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 border-gray-300 mb-4" placeholder="Type to search..." autoFocus autoCorrect="off" />
  )
}