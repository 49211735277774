import React, { useMemo, useState } from 'react'

import { Link } from 'gatsby'
import Button from '../button'
import { TimeAgo } from '../date'
import Empty from '../empty'
import Loading from '../loading'
import StatusBadge from '../status/badge'
import TableMediaPreview from './file-preview'
import TableSearch from './search'

export default function Table({ loading, records = [], enableSearch, searchQuery, setSearchQuery, to, showStatus = true, fetchMore, perPage, pageInfo, label }) {
  const [loacalLoading, setLocalLoading] = useState(false)

  return useMemo(() => {
    function handleLoadMore() {
      setLocalLoading(true)
      fetchMore({
        variables: {
          first: perPage,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          setLocalLoading(false)
          return Object.assign({}, prev, {
            records: {
              edges: [...prev.records.edges, ...fetchMoreResult.records.edges],
              pageInfo: fetchMoreResult.records.pageInfo
            }
          })
        },
      })
    }

    return (
      <>
        {enableSearch && <TableSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} label={label} />}
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-6">
                        Cover
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900">
                        Title
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900">
                        Updated
                      </th>
                      {showStatus && (
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-right">
                          Status
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {loading && (
                      <tr>
                        <td colSpan={4}>
                          <Loading />
                        </td>
                      </tr>
                    )}
                    {!loading && records.length === 0 && (
                      <tr>
                        <td colSpan={4}>
                          <Empty />
                        </td>
                      </tr>
                    )}
                    {!loading && records.map(record => (
                      <tr key={record.id} className='bg-gray-50 hover:bg-gray-100'>
                        <td className="whitespace-nowrap py-3 pl-4 pr-3 sm:pl-6">
                          {record?.coverImage && (
                            <div className="rounded-md overflow-hidden w-14 h-14">
                              <Link to={`${to}/${record?.id}`} className="inline-block relative w-14 h-14">
                                <TableMediaPreview
                                  asset={record?.coverImage}
                                />
                                <div className="rounded-md animate-pulse duration-500 bg-gray-500 absolute top-0 left-0 w-14 h-14" />
                              </Link>
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-3">
                          <Link to={`${to}/${record?.id}`} className="block">
                            <div className="font-medium text-gray-900">{record?.titleDe}</div>
                            <div className="text-gray-500 text-sm">{record?.slugDe}</div>
                          </Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 text-gray-500">
                          <TimeAgo date={record?.updatedAt} /> by {record?.updater?.displayName}
                        </td>
                        {showStatus && (
                          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 text-right font-medium sm:pr-6">
                            <StatusBadge status={record?.editingStatus} />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {pageInfo?.hasNextPage && (
            <div className='mt-4 flex justify-center w-full'>
              <Button
                title={loacalLoading ? 'Loading...' : 'Load More'}
                onClick={handleLoadMore}
                loading={loacalLoading}
              />
            </div>
          )}
        </div>
      </>
    )
  }, [loading, records, enableSearch, loacalLoading, setLocalLoading])
}
